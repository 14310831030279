import getPageData from '../server/getPageData.server'
import Page from './[[...slug]]'

export default Page

export async function getStaticProps ({ preview = false, previewData }) {
  const pageData = await getPageData('404', {
    active: preview,
    token: previewData?.token
  })

  return {
    props: {
      data: pageData,
      preview
    },
    revalidate: 60
  }
}
